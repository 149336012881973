<template>
  <div class="paymentDetail">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-6">
        <base-alerts />
        <h1>{{ title }}</h1>
        <connect-social-account
          @success="success"
          callback-url="/user/register/connect-social"
        />
      </div>
      <div class="col-md-6">
        <div class="image">
          <img
            src="@/assets/images/payment-detail.png"
            class="img-fluid"
            alt="payment-detail.png-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    connectSocialAccount: () =>
      import(
        /* webpackChunkName: "connectSocialAccount" */ "@/components/user/register/ConnectSocialAccount.vue"
      ),
  },
  data() {
    return {
      title: "Connect Social",
      processing: false,
      btnText: "Save",
      showUser: false,
    };
  },
  methods: {
    success() {
      this.$router.push("/user/register/story-price");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .invalid-feedback {
  position: static !important;
}
</style>
